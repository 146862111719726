<script lang="ts">
	import type { ProductCardMissingInfo$result } from '$houdini';
	import type { RequiredProduct } from '$lib/types';
	import { deduplicate } from '$lib/utils';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { mapToNode } from '$lib/utils/product';

	export let data: ProductCardMissingInfo$result;
	export let colorOptions: RequiredProduct['options'];
	export let parsedColorMap: Record<string, string>;
	export let sku: string | undefined;

	$: isMultiColor = (colorOptions?.length ?? 0) > 1;
	$: mappedVariants = deduplicate(mapToNode(data.product?.variants), (s1, s2) => {
		return (
			s1.selectedOptions.find((s) => s.name === colorOptions[0].name)?.value ===
				s2.selectedOptions.find((s) => s.name === colorOptions[0].name)?.value &&
			(isMultiColor
				? s1.selectedOptions.find((s) => s.name === colorOptions[1].name)?.value ===
					s2.selectedOptions.find((s) => s.name === colorOptions[1].name)?.value
				: true)
		);
	});
	$: currentVariant = mapToNode(data.product?.variants).find(
		(s) => s.sku?.value === sku
	)?.selectedOptions;
</script>

<div class="flex justify-center flex-wrap gap-3">
	{#if isMultiColor}
		{#each mappedVariants as variant}
			{@const colorOne = variant.selectedOptions.find(
				(s) => s.name === colorOptions[0].name
			)?.value}
			{@const colorTwo = variant.selectedOptions.find(
				(s) => s.name === colorOptions[1].name
			)?.value}
			{@const isSelected =
				currentVariant?.find((s) => s.name === colorOptions[0].name && s.value === colorOne) &&
				currentVariant?.find((s) => s.name === colorOptions[1].name && s.value === colorTwo)}
			{@const colorHexOne = parsedColorMap[colorOne || '']}
			{@const colorHexTwo = parsedColorMap[colorTwo || '']}
			<button
				on:click={() => (sku = variant.sku?.value || '')}
				title={`${colorOne} / ${colorTwo}`}
				class={classes(
					'rounded-full flex items-center aspect-square',
					isSelected
						? 'border-accent-primary border-2 -m-[1px]'
						: 'border border-accent-primary_005 hover:border-accent-primary_02'
				)}
			>
				<div class="rounded-l-full h-5 w-2.5" style:background-color={colorHexOne}></div>
				<div class="rounded-r-full h-5 w-2.5" style:background-color={colorHexTwo}></div>
			</button>
		{/each}
	{:else}
		{#each mappedVariants as variant}
			{@const colorOne = variant.selectedOptions.find(
				(s) => s.name === colorOptions[0].name
			)?.value}
			{@const isSelected = currentVariant?.find(
				(s) => s.name === colorOptions[0].name && s.value === colorOne
			)}
			{@const colorHexOne = parsedColorMap[colorOne || '']}
			<button
				on:click={() => {
					sku = variant.sku?.value || '';
				}}
				title={`${colorOne}`}
				class={classes(
					'rounded-full flex items-center aspect-square',
					isSelected
						? 'border-accent-primary border-2 -m-[1px]'
						: 'border border-accent-primary_005 hover:border-accent-primary_02'
				)}
			>
				<div class="rounded-full h-5 w-5" style:background-color={colorHexOne}>
					{colorHexOne ? '' : '?'}
				</div>
			</button>
		{/each}
	{/if}
</div>
