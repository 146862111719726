<script lang="ts">
	import type { ProductCardMissingInfo$result } from '$houdini';
	import { deduplicate } from '$lib/utils';
	import type { CompleteVariant } from '$lib/types';
	import ProductCardColorPicker from '$lib/components/Product/Card/ProductCardColorPicker.svelte';
	import { createEventDispatcher } from 'svelte';
	import { cart } from '$lib/store/cart.store';
	import GenericDisclosure from '$lib/components/GenericDisclosure.svelte';
	import { getTranslate } from '@tolgee/svelte';
	import { mapToNode, removeArchived } from '$lib/utils/product';

	export let data: ProductCardMissingInfo$result;
	export let distinctColorAndOptions: boolean = false;
	export let sku: string | undefined;

	$: isSingle = data.product?.is_single?.value === 'true';

	const dispatch = createEventDispatcher();
	const { t } = getTranslate();
	$: productVariants = removeArchived(mapToNode(data.product?.variants));

	const findSDSForNormal = (sku: string) => {
		return mapToNode(data.sdsProduct?.variants).find((variant) => variant.sku?.value === sku);
	};

	let distinctClosureOpen = false;
	let selectedOptions: CompleteVariant['selectedOptions'] = [];
	$: generatedOptions =
		selectedOptions
			?.map((option) => {
				return {
					[option.name]: option.value
				};
			})
			.reduce((acc, curr) => ({ ...acc, ...curr }), {}) || {};

	$: getOptionLimitationsOnLayer = (index: number, currentOptionName: string) => {
		if (index === 0) {
			return data.product?.options[0].values;
		}
		const optionsBefore = data.product?.options.slice(0, index).map((e) => e.name) || [];
		const availableVariants = productVariants.filter((variant) => {
			for (const option of optionsBefore) {
				if (
					variant.selectedOptions.find((e) => e.name === option)?.value !== generatedOptions[option]
				) {
					return false;
				}
			}
			return true;
		});
		return deduplicate(
			availableVariants.map((variant) => {
				const sdsVariant = findSDSForNormal(variant.sku?.value || '');
				const inStock =
					$cart.getRealQuantity(
						variant.sku?.value || '',
						Math.max(sdsVariant?.quantityAvailable || 0, variant.quantityAvailable || 0)
					) > 0;

				return inStock
					? variant.selectedOptions.find((e) => e.name === currentOptionName)?.value
					: undefined;
			})
		).filter((e) => !!e) as string[];
	};

	$: parsedColorMap = JSON.parse(data.product?.colormap?.value || '{}') ?? {};

	$: isOptionColorDisplayable = (optionValues: string[]) => {
		return (
			optionValues.filter((optionValue) => {
				try {
					return !!parsedColorMap[optionValue];
				} catch {
					return false;
				}
			}).length >
			optionValues.length / 2
		);
	};

	$: colorOptions = data.product?.options.filter((option) =>
		isOptionColorDisplayable(option.values)
	);
	$: nonColorOptions = data.product?.options.filter(
		(option) => !isOptionColorDisplayable(option.values)
	);

	$: if ((colorOptions?.length ?? 0) > 0 && sku) {
		const colorVariant = productVariants.find((variant) => variant.sku?.value === sku);
		if (colorVariant) {
			selectedOptions = [
				...selectedOptions.filter((e) => !colorOptions?.find((o) => o.name === e.name)),
				...colorVariant.selectedOptions.filter((e) => colorOptions?.find((o) => o.name === e.name))
			];
		}
	}

	const selectSKUFromSelectedOptions = () => {
		const variant = productVariants.find((variant) =>
			selectedOptions.every(
				(option) =>
					variant.selectedOptions.find((e) => e.name === option.name)?.value === option.value
			)
		);
		if (variant) {
			sku = variant.sku?.value;
			return true;
		}
		return false;
	};

	$: if (selectedOptions.length === data.product?.options.length && !isSingle) {
		// Whether a SKU was found or not, we want to complete the selection
		if (selectSKUFromSelectedOptions()) {
			dispatch('complete');
		} else {
			dispatch('incomplete');
		}
	}

	const setSkuForSingle = (isSingle: boolean) => {
		if (isSingle) {
			sku = productVariants[0].sku?.value || '';
			dispatch('complete');
			console.log('single', sku);
		}
	};

	$: setSkuForSingle(isSingle);
</script>

{#if !isSingle}
	<div class="flex flex-col gap-2.5 w-full">
		{#if (colorOptions?.length ?? 0) > 0 && colorOptions}
			<ProductCardColorPicker {data} {colorOptions} {parsedColorMap} bind:sku />
		{/if}
		{#if (nonColorOptions?.length ?? 0) > 0 && distinctColorAndOptions}
			<hr />
			<GenericDisclosure
				class="w-full flex-none"
				contentClassName="w-full"
				bind:open={distinctClosureOpen}
			>
				<button
					on:click={() => (distinctClosureOpen = !distinctClosureOpen)}
					class="flex w-full text-h-12 justify-between"
				>
					<span>
						{$t('bundles.choose-variant')}
					</span>
					<div class="i-sprenger-chevron-down w-4 h-4"></div>
				</button>
				<div slot="content-open">
					{#each nonColorOptions || [] as option}
						{@const depthIndex =
							data?.product?.options.findIndex((s) => s.name === option.name) || 0}
						{@const realOptions = getOptionLimitationsOnLayer(depthIndex, option.name) || []}
						<div class="flex flex-wrap items-center gap-2.5">
							<label for={option.name} class="text-h-12 font-medium flex justify-between">
								{option.name}
							</label>
							<fieldset name={option.name} id={option.name} class="flex gap-2 flex-wrap">
								{#each option.values as value}
									{@const isDisabled = !realOptions.find((o) => o === value)}
									{@const isSelected = generatedOptions[option.name] === value}
									<div
										id={`${option.name}-${value}-label`}
										class="border-1.5 rounded-lg p-2 cursor-pointer text-p-12 flex items-center gap-2"
										class:border-accent-primary={isSelected}
										class:border-dark-tertiary={!isSelected}
										class:opacity-50={isDisabled}
										role="button"
										tabindex="0"
										on:keydown={() => {}}
										on:click={() => {
											selectedOptions = [
												...selectedOptions.filter((e) => e.name !== option.name),
												{
													name: option.name,
													value
												}
											];
										}}
									>
										<input
											disabled={isDisabled}
											type="radio"
											name={option.name}
											id={`${option.name}-${value}`}
											{value}
											class="hidden"
										/>
										{value}
									</div>
								{/each}
							</fieldset>
						</div>
					{/each}
				</div>
			</GenericDisclosure>
		{:else}
			{#each nonColorOptions || [] as option}
				{@const depthIndex = data?.product?.options.findIndex((s) => s.name === option.name) || 0}
				{@const realOptions = getOptionLimitationsOnLayer(depthIndex, option.name) || []}
				<div class="flex flex-wrap items-center gap-2.5">
					<label for={option.name} class="text-h-12 font-medium flex justify-between">
						{option.name}
					</label>
					<fieldset name={option.name} id={option.name} class="flex gap-2 flex-wrap">
						{#each option.values as value}
							{@const isDisabled = !realOptions.find((o) => o === value)}
							{@const isSelected = generatedOptions[option.name] === value}
							<div
								id={`${option.name}-${value}-label`}
								class="border-1.5 rounded-lg p-2 cursor-pointer text-p-12 flex items-center gap-2"
								class:border-accent-primary={isSelected}
								class:border-dark-tertiary={!isSelected}
								class:opacity-50={isDisabled}
								role="button"
								tabindex="0"
								on:keydown={() => {}}
								on:click={() => {
									selectedOptions = [
										...selectedOptions.filter((e) => e.name !== option.name),
										{
											name: option.name,
											value
										}
									];
								}}
							>
								<input
									disabled={isDisabled}
									type="radio"
									name={option.name}
									id={`${option.name}-${value}`}
									{value}
									class="hidden"
								/>
								{value}
							</div>
						{/each}
					</fieldset>
				</div>
			{/each}
		{/if}
	</div>
{/if}
