import { derived, writable } from 'svelte/store';

const createGlobalAddedToCartStore = () => {
	const expanded = writable(false);
	const lineIDs = writable<string[]>([]);

	lineIDs.subscribe((value) => {
		if (value.length === 0) {
			expanded.set(false);
		}
	});

	expanded.subscribe((value) => {
		if (!value) {
			lineIDs.set([]);
		}
	});

	const data = derived([expanded, lineIDs], ([$expanded, $lineIDs]) => ({
		expanded: $expanded,
		lineIDs: $lineIDs
	}));

	return {
		...data,
		expanded,
		addLineIDs: (...ids: string[]) => {
			lineIDs.update((value) => [...value, ...ids]);
		},
		toggle: () => {
			expanded.update((value) => !value);
		},
		setIsExpanded: (value: boolean) => {
			expanded.set(value);
		}
	};
};

export const globalAddedToCartStore = createGlobalAddedToCartStore();
